export const NAVBAR_HEIGHT = 45
export const COMMANDBAR_HEIGHT = 30

export const ASPECT_RATIO = 4 / 3
export const LOCAL_MEDIA_WIDTH = 250
export const LOCAL_MEDIA_HEIGHT = LOCAL_MEDIA_WIDTH / ASPECT_RATIO

export const MAX_MEDIA_WIDTH = 1200
export const MAX_MEDIA_HEIGHT = MAX_MEDIA_WIDTH / ASPECT_RATIO

export const MIN_MEDIA_GRID_HEIGHT = 150
export const MIN_MEDIA_GRID_WIDTH = MIN_MEDIA_GRID_HEIGHT * ASPECT_RATIO

export const DOUBLE_CLICK_MS = 300

export const MAX_BANDWIDTH = 2048
export const MIN_BANDWIDTH = 256
export const VIDEO_RESOLUTION = 560
